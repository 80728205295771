import { createContext, useEffect, useContext, useState } from "react";
import { initSockets } from "../components/sockets";
import { RoomsContext } from "./roomsContext";
import { UserContext } from "./userContext";
import io from "socket.io-client";
import { server_address } from "../urls";

export const SocketContext = createContext();

export const socket = io(server_address);


export const SocketProvider = ({ children }) => {
    const [value, setValue] = useState({
        threadId: ''
    });

    const { updateServiceFromServer, changeLanguage } = useContext(RoomsContext);
    const { user } = useContext(UserContext)
    


    useEffect(() => {
        initSockets({ updateServiceFromServer, changeLanguage, setValue });

        return () => {
            cleanSockets();
        }
        
    }, [user]);


    function cleanSockets() {
        socket.off("connet");
        socket.off("disconnect");
        socket.off("check-ok");
        socket.off("services");
    }
    

    return(
        <SocketContext.Provider value={value} >
            { children }
        </SocketContext.Provider>
    )

};

