import { socket } from "./index";

/**
 * Update a hotel service or force tablet to read the services in order to update them.
 * @param {String} type Action that we would like perform on service
 * @param {Boolean} value State of service 
 * @param {String} building Service room id 
 */

export 	const emitUpdate = (type, value, building) => {
    socket.emit('services',  { type: type, value:value, building:building, platform: 'web'} );
};

