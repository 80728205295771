import React, { useContext, useEffect , useState} from 'react';
// import { UserContext } from "../contexts/userContext";
import './MainTest.css';
import { gapi } from "gapi-script";
import { loadEvents } from '../components/handleCalendar';
import CustomCalendarView from '../custom-Calendar/CustomCalendarView';
import CalendarContext from '../contexts/calendarContext/CalendarContext';
import {ReactComponent as CalendarImg} from '../assets/svgs/calendar.svg';


const config ={
  API_KEY: "AIzaSyBMZlKXNBVa21Isw_JusoeLBoU8WGsnm60",
  SCOPES: 'https://www.googleapis.com/auth/calendar',
  CLIENT_ID: '277814438063-sa9dlshrqms4mn36pt62nttg0v59tij1.apps.googleusercontent.com',
}

const CalendarPage =  () => {
    const { setEvents } = useContext(CalendarContext);
    const [ isLoggedIn , setIsLoggedIn ] = useState(false);

    function getEvents(){
      loadEvents().then(response => {
        const events = response.result.items;
        setEvents(events);
      });
    }

    useEffect(() => {
      gapi.load('client:auth2', () => {
        gapi.auth2.init({
          apiKey: config.API_KEY,
          client_id: config.CLIENT_ID,
          scope: config.SCOPES
        }).then(() => {
          const isLoggedIn = gapi.auth2.getAuthInstance().isSignedIn.get();
          setIsLoggedIn(isLoggedIn);
          if(isLoggedIn){
            gapi.client.load('calendar', 'v3', () => {
              getEvents();
            });
          }else{
            handleSignInClick();
          }
        });
      });
    }, []);


    function handleSignInClick() {
      gapi.auth2.getAuthInstance().signIn().then(async () => {
        const isLoggedIn = await gapi.auth2.getAuthInstance().isSignedIn.get();
        setIsLoggedIn(isLoggedIn);
        if (isLoggedIn) {
          gapi.client.load('calendar', 'v3', () => {
            getEvents();
          });
        }
        
      });
    }

    // function handleSignOutClick() {
    //   gapi.auth2.getAuthInstance().signOut().then(() => {
    //     setEvents([]);
    //     setIsLoggedIn(false);
    //   });
    // }


  return (
      <>
        <div className='calCont'> 
          <CalendarImg
            fill="orange"
          className='calendarImg'
          />
        </div>

        <div className="containerForm">
          <div className = "emailAndSubmitForm">
                  <div className="emailContainer">
                    <p className="input customTxt calendarPlaceholder">
                      CALENDAR WANTS TO ACCESS TO YOUR GOOGLE ACCOUNT
                    </p>
                  </div>
                  <div className="submitContainer" onClick={handleSignInClick}>

                    {!isLoggedIn &&  <span className="calendarSubmit"> SIGN IN</span>}
                  </div>
                  
              </div>
          
          
          {isLoggedIn  && <CustomCalendarView />}
        </div>
      </>

    
  );
}

export default CalendarPage;
