import dayjs from "dayjs";
import React, { useContext, useState, useEffect } from "react";
import CalendarContext from "../../contexts/calendarContext/CalendarContext";
import './rrule.css'

export default function Day({ day, rowIdx }) {
  const [dayEvents, setDayEvents] = useState([]);
  const {
    setDaySelected,
    setShowEventModal,
    filteredEvents,
    setSelectedEvent,
  } = useContext(CalendarContext);

  useEffect(() => {
    const events = filteredEvents.filter((evt => {
      let date = evt.start?.date || evt.start?.dateTime
      return dayjs(date).format("DD-MM-YY") === day.format("DD-MM-YY")
    }))
    setDayEvents(events);
  }, [filteredEvents, day]);

  function getCurrentDayClass() {
    return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
      ? "bg-orangeMain text-white rounded-full w-7"
      : "";
  }

  function validDay(){
    return (day.format() > dayjs().format() || day.format("DD-MM-YY") === dayjs().format("DD-MM-YY"))
  }

  function handleDayClicked(day){
    if(validDay()){
      setDaySelected(day);
      setShowEventModal(true);
    }
  }

  function getTime(date){
    let d = new Date(date)
    let timeArray = d.toTimeString().split(' ')[0].split(':')
    return (timeArray[0] +":"+timeArray[1])
  }


  return (
    <div className={`
      border border-gray-200 flex flex-col overflow-hidden min-h-40
      ${
        validDay() ? "":"bg-gray-300"
       
      }`}
    >
      <header className="flex flex-col items-center">
        {rowIdx === 0 && (
          <p className="text-sm mt-1">
            {day.format("ddd").toUpperCase()}
          </p>
        )}
        <p
          className={`text-sm p-1 my-1 text-center  ${getCurrentDayClass()}`}
        >
          {day.format("DD")}
        </p>
      </header>
      <div
        className={`
          flex-1
          ${
            validDay() ? "cursor-pointer":""
          }`}
        onClick={() => handleDayClicked(day)}
      >
         {dayEvents.map((evt, idx) => (
              evt.start.date ?
              <div
                key={idx}
                onClick={() => validDay() ? setSelectedEvent(evt): null}
                className={`bg-mainColor p-1 text-left mr-3 pl-3 text-white text-sm rounded mb-1 truncate hover:bg-orangeMain`}
              >
                {evt.summary || "No title"}
              </div>
              : //else
              <div
                key={idx}
                onClick={() => validDay() ? setSelectedEvent(evt): null}
                className={`flex items-center p-1 text-left mr-3 pl-3 text-gray-600 text-sm rounded mb-1 truncate hover:bg-gray-200 eventContainer`}
              >
                <div className="w-4 h-4 bg-mainColor rounded-full mr-2 eventDot"/>
                {getTime(evt.start.dateTime)} {evt.summary || "No title"}
              </div>
            ))}
      </div>
    </div>
  );
}
