import { socket } from "./index";

/**
 * Init socket events
 * @param {Function} updateServiceFromServer Get updated services from server
 */
export function SocketEvents({updateServiceFromServer, changeLanguage}) {

    socket.on("connect", () => {
        console.log("Listeting to server connect MAIN");
    });

    socket.on("disconnect", () => {
        console.log("Listening disconnect from server MAIN");
    });

    socket.on("check-ok", () => {
        console.log("sockets working");
    });

    socket.on("services", (item) => {
        let buildingId = item.buildingID;
        //getRooms();
        updateServiceFromServer(item.type, item.value, buildingId);
    });

    socket.on("changeLanguage" , (item) => {
        changeLanguage(item)
    })
}