import { useContext, useEffect, useState } from "react";
import { UserContext } from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import { RoomsContext } from "../contexts/roomsContext";
//import { RoomCalls } from "../components/RoomCalls";
import "./roomDetails/MainHeader.css";
import logo from "../assets/imgs/marriott_logo.png"

const MainHeader = () => {
	const { user, logOutUser } = useContext(UserContext);
	const [dateTime, setDateTime] = useState(new Date());
	let { rooms, currentRoom, setCurrentRoom } = useContext(RoomsContext);

	const navigate = useNavigate();

	useEffect(() => {
		setInterval(() => setDateTime(new Date()), 1000);
	}, []);

	const handleLogout = () => {
		logOutUser();
		setCurrentRoom("");
		navigate("/login", { replace: true });
  	};
	/**
	 * Logout
	 */
	
	return (
		
		<div className="backgroundColor">
			{/* Empty element for positioning purposes */}
			<div> 
				{rooms.map((room) =>
					room?.calling === true && (<div className="bg-blue-950 w-60"></div>))}
			</div>
				
			
			{/* Hotel logo */}
			{user && <img className={currentRoom && currentRoom?.hotel?.checkOut ? "textHeaderCurrentRoom" : "textHeaderInitial" } onClick={() => navigate('/')} src={logo} alt="Logo"/>}
			<div className={currentRoom && currentRoom?.hotel?.checkOut ? "logoutAndDateContainerCurrentRoom " : "logoutAndDateContainerInitial "}>
				{/* Logout Button */}
				<div className={currentRoom && currentRoom?.hotel?.checkOut ? "textStyleCurrentRoom" : "textStyleInitial"}>
					{user && <button onClick={handleLogout} className={currentRoom && currentRoom?.hotel?.checkOut ? "buttonStyleCurrentRoom" : "buttonStyleInitial"}>
						Logout
					</button>}
				</div>
						{/* Date and time*/}
				<div className={currentRoom && currentRoom?.hotel?.checkOut ? "dateTimeStyleCurrentRoom" : "dateTimeStyleInitial"}>
							{dateTime.toLocaleDateString("el-GR", {
							weekday: "long",
							day: "numeric",
							month: "numeric",
							year: "numeric"
							})}
							{` `}
							{dateTime.toLocaleTimeString("el-GR", {
							hour: "numeric",
							minute: "numeric",
							hour12: false
							})}
				</div>
			</div>
		</div>
		
	);
};

export default MainHeader;
