import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
	apiKey: "AIzaSyABrmdwvI_deXAkBjxiFkD0ZgFySt0F1W8",
	authDomain: "rea-nurse.firebaseapp.com",
	databaseURL: "https://rea-nurse.firebaseio.com",
	projectId: "rea-nurse",
	storageBucket: "rea-nurse.appspot.com",
	messagingSenderId: "481449809875",
	appId: "1:481449809875:web:30db04a21b35aa02eb8126"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

const onMessageListener = () => {
	return new Promise((resolve) => {
		onMessage(messaging, (payload) => {
			//console.log("Message received. ", payload);
			resolve(payload);
		});
	});
};

export { messaging, onMessageListener };
