import React, { lazy, Suspense, useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import MainHeader from "./components/MainHeader";
import CalendarPage from "./pages/Calendar";
import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";
import getBrowserFingerprint from "get-browser-fingerprint";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./contexts/userContext";
import { 
	getBuildingsForUser,
	getDevicesForBuilding,
	getMessages,
	saveNotificationToken } from "./utils/apiCalls";
import { RoomsContext } from "./contexts/roomsContext";

// import Login from "./pages/Login";
// import Main from "./pages/Main";

const Main = lazy(() => import("./pages/Main"));
const Login = lazy(() => import("./pages/Login"));

const App = () => {
	const { user, setNotificationToken} = useContext(UserContext);
	const navigate = useNavigate();
	const { storeRooms } = useContext(RoomsContext);

	useEffect(() => {
		if(!user) {
			navigate("/login");
		} else {
			initializeBuildings();
			requestPermission();
		}
		return() => {
			let rooms = [];
			storeRooms(rooms);
		}
	
		
	}, [user]);


	const requestPermission = () => {
		Notification.requestPermission().then((permission) => {
			if (permission === "granted") {
				// console.log("Notification permission granted.");
				getToken(messaging, {
					vapidKey:
						"BDOU99-h67HcA6JeFXHbSNMu7e2yNNu3RzoMj8TM4W88jITfq7ZmPvIM1Iv-4_l2LxQcYwhqby2xGpWwzjfAnG4"
				})
					.then((notificationToken) => {
						// console.log("currentToken", notificationToken);
						if (notificationToken) {
							setNotificationToken(notificationToken);
							// Send the token to your server and update the UI if necessary
							const fingerprint = getBrowserFingerprint();
							const signedToken = user?.signedToken;
							saveNotificationToken(
								notificationToken,
								signedToken,
								fingerprint
							);
						}
					})
					.catch((err) => {
						console.log(
							"An error occurred while retrieving token. ",
							err
						);
					});
			} else {
				console.log("Unable to get permission to notify.");
			}
		});
	};


	const initializeBuildings = async () => {
		// Get the token
		const signedToken = user?.signedToken;
		getBuildingsForUser(signedToken)
			.then(async (response) => {
				// console.log(response.data.data.buildings);
				const buildings = response.data.data.buildings;
				// Sort the buildings by name
				buildings.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}
					return 0;
				});

				buildings.forEach((building) => {
					getDevicesForBuilding(
						building.buildingID,
						signedToken
					).then((response) => {
						building.devices = response?.data?.data?.devices;
					});
				});

				const messages = await getMessages(signedToken)
					.then((messages) => {
						return messages.data.data;
					})
					.catch((error) => {
						console.error("Error getting messages");
					});

				// For each building, set the messages
				buildings.forEach((building) => {
					building.messages = messages.filter(
						(message) => message.buildingId === building.buildingID
					);
				});
				// Set the rooms
				console.log("getBuildingsForUser")
				storeRooms(buildings);
			})
			.catch((error) => {
				console.error("Error getting buildings" , error);
			});
	};


	return (
		<section className="flex h-full" style={{flexFlow:"column"}}>
            <MainHeader />
			<Routes>
				{/* Main page */}
				<Route
					index
					path="/"
					element={
						<Suspense fallback={<div>Loading...</div>}>
								<Main />
						</Suspense>
					}
				/>
				{/* Login page */}
				<Route
					path="login"
					element={
						<Suspense fallback={<div>Loading...</div>}>
							<Login />
						</Suspense>
					}
				/>
				<Route
					path="calendar"
					element={
						<Suspense fallback={<div>Loading...</div>}>
							<CalendarPage />
						</Suspense>
					}
				/>
			</Routes>
			<div className="flex-[0_1_40px]"/>
		</section>
		
	);
};

export default App;
