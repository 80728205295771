import { createContext, useEffect, useState } from "react";
import { emitUpdate } from "../components/sockets/emit";

/**
 * React Context for rooms
 */
export const RoomsContext = createContext({
	// This is the default value
	// It will be overwritten by the provider
	rooms: [],
	currentRoom: "",
	setCurrentRoom: () => {},
	setRooms: () => {},
	deleteMessageFromRoom: () => {},
	updateAllMessages: () => {},
	updateAllMessagesFromServer: () => {},
	updateCalls: () => {},
	removeCall: () => {},
	updateServices: () => {},
	updateServiceFromServer: () => {},
	storeRooms: () => {},
	checkIn: () => {},
	checkOut: () => {},
	changeLanguage: () => {},
});

/**
 * React Context Provider for rooms
 * @returns rooms,
 *			setRooms,
 *			deleteMessageFromRoom,
 *			updateAllMessages,
 *			updateCalls,
 *			removeCall,
			updateServices,
			updateServiceFromServer,
			storeRooms,
			checkIn,
			checkOut,
 */
export const RoomsProvider = ({ children }) => {
	let [rooms, setRooms] = useState([]);
	const [currentRoom, setCurrentRoom] = useState(null);


	useEffect(() => {
		if (rooms != null && rooms.length > 0) {
			
			rooms.forEach((room, index)=>{
				if(room?.hotel && room?.hotel.checkOut && room.devices){
					emitUpdate('read', false , room)
				}
			})
		} else {
			console.log("Rooms are empty");
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rooms]);

	const deleteMessageFromRoom = (roomId, messageId) => {
		let index = rooms.findIndex(room=>room.buildingID === roomId)
		if(index>-1) {
			let mIndex = rooms[index].messages.findIndex(m=>m.id===messageId)
			if(mIndex>-1){
				rooms[index].messages.splice(mIndex,1);
			}
		}

		let newRooms = rooms.map(room=>room);
		storeRooms(newRooms);
	};

	const updateAllMessages = (message) => {
		// debugger;
		let newRooms = rooms.map((room) => {
			if (room.buildingID === message.buildingId) {
				room.hotel.read = false;
				emitUpdate('read', false , room)
				const newMessages = [...room.messages, message];
				return { ...room, messages: newMessages };
			}
			return room;
		});
		storeRooms(newRooms);
	};

	const updateAllMessagesFromServer = (message) => {
		let newRooms = rooms.map((room) => {
			if(room.buildingID === message.buildingId) {
				room.hotel.read = false;
				const newMessages = room.messages;
				newMessages.push(message);
				emitUpdate('read', false , room)
				return {...room, messages: newMessages };
			}
			return room;
		});
		storeRooms(newRooms);
		
	};

	const updateCalls = (call) => {
		// If the room exists, update the call in the room
		if (rooms.find((r) => r.buildingID === call.buildingId)) {
			let newRooms = rooms.map((r) => {
				if (r.buildingID === call.buildingId) {
					r.hotel.read = false;
					emitUpdate('read', false , r)
					r.calling = true;
				}
				return r;
			});
			storeRooms(newRooms);
		}
	};

	const removeCall = (buildingId) => {
		let newRooms = rooms.map((r) => {
			if (r.buildingID === buildingId) {
				r.calling = false;
			}
			return r;
		});
		storeRooms(newRooms);
	};


	const storeRooms = (CurrentRooms) => {
		rooms = CurrentRooms;
		setRooms(CurrentRooms);
	}

	const updateServiceFromServer = (type, value, buildingId) => {
		if(rooms.length === 0)return;
		let newRooms = rooms.map((r) => {
			if(r.buildingID === buildingId) {
				switch(type) {
					case 'disturb':
						r.hotel.disturb = value;
						break;
					case 'cleanMyRoom':
						r.hotel.cleanMyRoom = value
						break;
					case 'linen':
						r.hotel.linen = value
						break;
					case 'towels':
						r.hotel.towels = value
						break;
					case 'premium':
						r.hotel.premium = value
						break;
					default:
						console.log("Default");
						break;
				}
			}
			return r;
		});
		storeRooms(newRooms);
	}

	const updateServices = (type,value) => {
		const services = ["disturb", "cleanMyRoom", "linen", "towels", "premium", "checkOut", "read"];
		let newRooms = rooms.map((r) => {
			if(r.hotel){
				if(r.buildingID === currentRoom?.buildingID){
					if(services.includes(type)){
						r.hotel[type] = value || !r.hotel[type];
						emitUpdate(type, r.hotel[type], r);
					}
				}
			}
			return r
		});
		storeRooms(newRooms);
	}

	const checkIn = () =>{
		checkInOut(true)
	}
	const checkOut = () =>{
		checkInOut(false)
	}

	const checkInOut = (value) => {
		let newRooms = rooms.map((r) => {
			if(r.buildingID === currentRoom?.buildingID){
				r.messages = [];
				r.hotel={
					disturb: false,
					cleanMyRoom: false,
					linen: false,
					towels:false,
					premium: false,
					checkOut: value,
					read: false
				}
			}
			return r
		});
		storeRooms(newRooms);
	}

	const changeLanguage = (item) =>{
		const newRooms = rooms.map((r) => {
			if(r.buildingID === item?.buildingID){
				r.settings.language = item.lang;
			}
			return r
		})
		storeRooms(newRooms);
	}

	return (
		<RoomsContext.Provider
			value={{
				rooms,
				currentRoom,
				setCurrentRoom,
				setRooms,
				deleteMessageFromRoom,
				updateAllMessages,
				updateAllMessagesFromServer,
				updateCalls,
				removeCall,
				updateServices,
				updateServiceFromServer,
				storeRooms,
				checkOut,
				checkIn,
				changeLanguage
			}}
		>
			{children}
		</RoomsContext.Provider>
	);
};
