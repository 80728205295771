import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import CalendarContext from "../../contexts/calendarContext/CalendarContext";
import { loadEvents } from "../../components/handleCalendar";
export default function CalendarHeader() {

  const [ rotate , setRotate ] = useState(false);
  const { monthIndex, setMonthIndex, setEvents } = useContext(CalendarContext);

  function handlePrevMonth() {
    setMonthIndex(monthIndex - 1);
  }
  function handleNextMonth() {
    setMonthIndex(monthIndex + 1);
  }
  function handleReset() {
    setMonthIndex(
      monthIndex === dayjs().month()
        ? monthIndex + Math.random()
        : dayjs().month()
    );
  }

  function onRefresh(){
    setRotate(true);
    
    loadEvents().then(response => {
      const events = response.result.items;
      setRotate(false);
      setEvents(events);
    });
    
  }

  return (
    <header className="relative px-4 py-2 flex items-center">
      <button
        onClick={handleReset}
        className="border rounded py-2 px-4 mx-5"
      >
        Today
      </button>
      <button onClick={handlePrevMonth}>
        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
          chevron_left
        </span>
      </button>
      <button onClick={handleNextMonth}>
        <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
          chevron_right
        </span>
      </button>
      <h2 className="ml-4 text-xl text-gray-500 font-bold">
        {dayjs(new Date(dayjs().year(), monthIndex)).format(
          "MMMM YYYY"
        )}
      </h2>
      <span onClick={onRefresh} className={`text-gray-600 material-symbols-outlined select-none absolute cursor-pointer right-8 hover:${rotate ? "": "-rotate-45 "} ${rotate ? "animate-spin" : ""}`}>
        autorenew
      </span>
    </header>
  );
}
