import { gapi } from "gapi-script";

const config ={
    CALENDAR_ID: "7accae8d715a6765f09f56b832661043cc4f7984fe4a2a7b1d17c1909530048f@group.calendar.google.com",
    API_KEY: "AIzaSyBMZlKXNBVa21Isw_JusoeLBoU8WGsnm60",
    DISCOVERY_DOC: 'https://www.googleapis.com/calendar/v3/calendars',
    SCOPES: 'https://www.googleapis.com/auth/calendar',
    CLIENT_ID: '277814438063-sa9dlshrqms4mn36pt62nttg0v59tij1.apps.googleusercontent.com'
  }


export function loadEvents() {
    let events = gapi.client.calendar.events.list({
        calendarId: config.CALENDAR_ID,
        timeMin: new Date().toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString(),
        showDeleted: false,
        singleEvents: true,
        orderBy: 'startTime'
    });
    return events
};

export function getEvent(eventID) {
    let event = gapi.client.calendar.events.get({
        calendarId: config.CALENDAR_ID,
        eventId: eventID,
        singleEvents: true
    });
    return event
};

export function addEvent(event) {
    if (event.id) {
        return gapi.client.calendar.events.update({
            calendarId: config.CALENDAR_ID,
            resource: event,
            eventId:event.id
        });
    }
    return gapi.client.calendar.events.insert({
        calendarId: config.CALENDAR_ID,
        resource: event
    });
};

export function deleteEvent(eventId) {
    return gapi.client.calendar.events.delete({
        calendarId: config.CALENDAR_ID,
        eventId: eventId
    });
};

