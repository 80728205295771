import React, { useContext, useState } from "react";
import CalendarContext from "../../contexts/calendarContext/CalendarContext";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from "dayjs";
import { Checkbox } from "@mui/material";
import { useEffect } from "react";
import { addEvent, deleteEvent, loadEvents, getEvent } from "../../components/handleCalendar";
import RRuleGenerator from 'react-rrule-generator';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './rrule.css'

export default function EventModal() {
  const [ repeat , setRepeat ]= useState(false);
  const [ RRULE , setRRULE ] = useState("RRULE:FREQ=MONTHLY;INTERVAL=1;BYMONTHDAY=1;WKST=SU");

  const {
    setShowEventModal,
    daySelected,
    setDaySelected,
    dispatchCalEvent,
    selectedEvent,
    setEvents
  } = useContext(CalendarContext);

  const [summary, setSummary] = useState(
    selectedEvent ? selectedEvent.summary : ""
  );
  const [sumErr, setSumErr] = useState("");

  const [isAllDay, setIsAllDay] = useState(true);

  const [description, setDescription] = useState(
    selectedEvent ? selectedEvent.description : ""
  );

  const [ startDate , setStartDate ] = useState('')
  const [ endDate , setEndDate ] = useState('')


  useEffect(()=>{
    if (selectedEvent){
      if(selectedEvent.start.date){
        //all day event
      }else{
        setStartDate(dayjs(selectedEvent.start.dateTime).toISOString());
        setEndDate(dayjs(selectedEvent.end.dateTime).toISOString());
        setIsAllDay(false);
      }

      if (selectedEvent.recurringEventId){
        if (selectedEvent.rrule) {
          setRRULE(selectedEvent.rrule);
          setRepeat(true);
        }else{
          getEvent(selectedEvent.recurringEventId).then((res)=>{
            setRRULE(res.result.recurrence[0]);
            setRepeat(true);
          })
        }
      }
    }
  },[selectedEvent])

  function handleSubmit(e) {
    e.preventDefault();

    if (!summary || summary === "") {
      setSumErr("Title can not be empty!");
      return;
    }

    let calendarEvent = {
      summary: summary,
      start: isAllDay ? {
        date:dayjs(daySelected).format("YYYY-MM-DD")
      }:{
        dateTime: new Date(startDate).toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
      },
      end: isAllDay ? {
        date: dayjs(daySelected).add(1,'day').format("YYYY-MM-DD")
      }:{
        dateTime: new Date(endDate).toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone.toString()
      },
      recurrence:repeat ?[
        RRULE,
      ]:null,
      description: description,
    };


    if (selectedEvent){calendarEvent.id = selectedEvent.id}
    addEvent(calendarEvent).then(res=>{
      if(calendarEvent.recurrence){
        loadEvents().then(response=> {
          const events = response.result.items
          events.forEach((event)=>{
            event.rrule = RRULE
            console.log(event);
          })
          setEvents(events) 
        })
      }else{
        if (selectedEvent) {
          dispatchCalEvent({ type: "update", payload: res.result });
        } else {
          dispatchCalEvent({ type: "push", payload: res.result });
        }
      }
    });
  
    setShowEventModal(false);
  };

  const multiDelete = () =>{
    confirmAlert({
			title: `Deleting a recurring event`,
			// message: "This action will reset user's actions and delete all their messages!",
			buttons: [
				{
				label: 'Cancel',
				},
				{
				label: 'Delete Selected',
				onClick:()=>{handleDeleteEvent(selectedEvent.id, "delete")}
				},
				{
				label: 'Delete All',
				onClick:()=>{handleDeleteEvent(selectedEvent.recurringEventId, "multiDelete")}
				}
			]
		});
  };

  function handleDeleteEvent(id , type) {
    deleteEvent(id).then((res)=>{
      dispatchCalEvent({
        type: type,
        payload: selectedEvent,
      });
      setShowEventModal(false);
    }).catch((e)=> console.log(e))
  };

  function dateChange(datePicker) {
      setStartDate(dayjs(dayjs(datePicker).format("YYYY-MM-DD")+' '+dayjs(startDate).format("hh:mm")));
      setEndDate(dayjs(dayjs(datePicker).format("YYYY-MM-DD")+' '+dayjs(endDate).format("hh:mm")))
    setDaySelected(datePicker)
  };

  function handleStart(date){
    setStartDate(dayjs(dayjs(daySelected).format("YYYY-MM-DD")+' '+dayjs(date).format("HH:mm")));
  };
  function handleEnd(date){
    setEndDate(dayjs(dayjs(daySelected).format("YYYY-MM-DD")+' '+dayjs(date).format("HH:mm")))

  };

  function handleClose(e){
    console.log(e.target.id);
    if (e.target.id === 'OuterContainer') setShowEventModal(false);
  };

  return (
    <div onClick={handleClose} id="OuterContainer" className="fixed h-screen w-full left-0 top-0 flex justify-center items-center">
      <form className="bg-white rounded-lg shadow-2xl w-1/2 select-none">
        <header className="bg-gray-100 px-4 py-2 flex justify-between items-center">
          <p className="text-gray-400">{daySelected.format("dddd, MMMM DD")}</p>
          <div>
            {selectedEvent &&
              (selectedEvent.recurringEventId ? (
              <span
                onClick={multiDelete}
                className="material-icons-outlined text-gray-400 cursor-pointer"
              >
                delete
              </span>
              ):
              <span
                onClick={()=>handleDeleteEvent(selectedEvent.id, "delete")}
                className="material-icons-outlined text-gray-400 cursor-pointer"
              >
                delete
              </span>)
              }
            <button onClick={() => setShowEventModal(false)}>
              <span className="material-icons-outlined text-gray-400">
                close
              </span>
            </button>
          </div>
        </header>
        <div className="p-3">
          <div className="grid grid-cols-1/5 items-center gap-y-7">
            <span className="material-icons-outlined text-gray-400">
                title
            </span>
            <div>
              <input
                type="text"
                name="summary"
                placeholder="Add title"
                value={summary}
                className="pt-3 border-0 text-gray-600 text-xl font-semibold pb-2 w-full border-b-4 border-gray-200 focus:outline-none focus:ring-0 focus:border-black-500"
                onChange={(e) => {setSummary(e.target.value); setSumErr("")}}
              />
              <span className="text-red-400" >{sumErr}</span>
            </div>
            <span className="material-icons-outlined text-gray-400">
              schedule
            </span>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="flex justify-between items-center pb-4">
                  <DatePicker
                    value={daySelected}
                    onAccept={dateChange}
                  />
                  <span className="cursor-pointer" onClick={()=>setIsAllDay(!isAllDay)} >All day<Checkbox checked={isAllDay} /></span> 
                </div>
                {!isAllDay && <div className="flex">
                  <TimePicker label="Start" ampm={false} value={dayjs(startDate)} onChange={handleStart} />
                  <TimePicker label="End" ampm={false} value={dayjs(endDate)} onChange={handleEnd} />
                </div>}
              </LocalizationProvider>
            </div>
            <span className="material-icons-outlined text-gray-400">
              segment
            </span>
            <input
              type="text"
              name="description"
              placeholder="Add a description"
              value={description}
              className="pt-3 border-0 text-gray-600 pb-2 w-full border-b-2 border-gray-200 focus:outline-none focus:ring-0 focus:border-blue-500"
              onChange={(e) => {setDescription(e.target.value) }}
            />
          </div>
        </div>
        <div className="relative mx-4">
          {repeat ?
            <>
              <span className="material-icons-outlined cursor-pointer absolute w-7 right-4 top-4" onClick={()=>setRepeat(false)}>
                close
              </span>
              <RRuleGenerator
                onChange={(rrule) => setRRULE(rrule)}
                value={RRULE}
                config={{
                  repeat: ['Monthly', 'Weekly', 'Daily'],
                  yearly: 'on the',
                  monthly: 'on',
                  end: ['Never', 'On date'],
                  weekStartsOnSunday: true,
                  hideError: true,
                }}
              />
            </>
            :
            <div className="py-3 text-center border rounded cursor-pointer" onClick={()=>setRepeat(true)}>Enable Recurrence</div>
          }
        </div>
        <footer className="flex justify-end border-t p-3 mt-5">
          <button
            type="submit"
            onClick={handleSubmit}
            className="bg-gray-400 hover:bg-black px-6 py-2 rounded text-white"
          >
            Save
          </button>
        </footer>
      </form>
    </div>
  );
}
