import React, {
  useState,
  useEffect,
  useReducer,
  useMemo,
} from "react";
import CalendarContext from "./CalendarContext";
import dayjs from "dayjs";

function savedEventsReducer(state, { type, payload }) {
  console.log(payload);
  switch (type) {
    case "init":
      return [...payload];
    case "push":
      return [...state, payload];
    case "update":
      return state.map((evt) =>
        evt.id === payload.id ? payload : evt
      );
    case "delete":
      return state.filter((evt) => evt.id !== payload.id);
    case "multiDelete":
      return state.filter((evt) => {
        return evt.recurringEventId !== payload.recurringEventId
      });
    default:
      throw new Error();
  }
}



export default function ContextWrapper({children}) {
  const [monthIndex, setMonthIndex] = useState(dayjs().month());
  const [smallCalendarMonth, setSmallCalendarMonth] = useState(null);
  const [daySelected, setDaySelected] = useState(dayjs());
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [savedEvents, dispatchCalEvent] = useReducer(
    savedEventsReducer,
    [],
  );

  const filteredEvents = useMemo(() => {
    return savedEvents
  }, [savedEvents]);

  function setEvents(events){
    dispatchCalEvent({ type: "init", payload: events });
  }

  useEffect(() => {
    if (smallCalendarMonth !== null) {
      setMonthIndex(smallCalendarMonth);
    }
  }, [smallCalendarMonth]);

  useEffect(() => {
    if (!showEventModal) {
      setSelectedEvent(null);
    }
  }, [showEventModal]);

  return (
    <CalendarContext.Provider
      value={{
        monthIndex,
        setMonthIndex,
        smallCalendarMonth,
        setSmallCalendarMonth,
        daySelected,
        setDaySelected,
        showEventModal,
        setShowEventModal,
        dispatchCalEvent,
        selectedEvent,
        setSelectedEvent,
        savedEvents,
        filteredEvents,
        setEvents,
      }}
    >
      {children}
    </CalendarContext.Provider>
  );
}
