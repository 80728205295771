import {
	login as loginUrl,
	getBuildingsForUser as buildingsUrl,
	getBuildingDevicesUrl as devicesUrl,
	deletemessageUrl,
	messagesUrl,
	setNotificationTokenUrl,
	requestVideoCall,
	actionsUrl,
	massiveMsgsURL,
	checkOutUrl
} from "../urls";

import axios from "axios";
import md5 from "md5";

/**
 * Login to the server
 * @param {string} email
 * @param {string} password
 * @returns {Promise} A promise that resolves to the login response
 */

export const login = (email, password) => {
	return axios.post(loginUrl, {
		email: email,
		password: md5(password),
		origin: 2
	});
};

/**
 * Get all buildings for a user
 * @param {string} signedToken
 * @returns {Promise} A promise that resolves to the buildings
 */
export const getBuildingsForUser = (signedToken) => {
	return axios.get(buildingsUrl, {
		headers: {
			"Content-Type": "application/json",
			Authorization: signedToken
		}
	});
};

/**
 * Get all messages for a user
 * @param {string} signedToken
 * @returns {Promise} A promise that resolves to the messages
 */

export const getMessages = async (signedToken) => {
	return await axios.get(messagesUrl, {
		headers: {
			"Content-Type": "application/json",
			Authorization: signedToken
		}
	});
};

/**
 * Delete a message from the database
 * @param {string} messageId
 * @param {string} signedToken
 * @returns {Promise} A promise that resolves to the status of the delete
 */
export const deleteMessageFromDatabase = (messageId, signedToken) => {
	return axios.delete(deletemessageUrl + "/" + messageId, {
		headers: {
			"Content-Type": "application/json",
			Authorization: signedToken
		}
	});
};
// handle from server init a room in server with checkOut true or false
export const checkInOutRoomServer = (roomID , value , signedToken) => {
	return axios.post(checkOutUrl + "/" + roomID , {
		buildingID: roomID,
		value: value
	},{
		headers: {
			"Content-Type": "application/json",
			Authorization: signedToken
		}
	});
};

/**
 * Get all devices for a building
 * @param {string} buildingId
 * @param {string} signedToken
 * @returns {Promise} A promise that resolves to the devices
 */
export const getDevicesForBuilding = async (buildingId, signedToken) => {
	return await axios.get(devicesUrl + "/" + buildingId, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: signedToken
		}
	});
};

/**
 * Set the notification token on the server
 * @param {string} notificationToken
 * @param {string} signedToken
 * @param {string} uniqueId
 */

export const saveNotificationToken = (
	notificationToken,
	signedToken,
	uniqueId
) => {
	axios
		.post(
			setNotificationTokenUrl,
			{
				notificationToken: notificationToken,
				uniqueID: uniqueId,
				type: "web"
			},
			{
				headers: {
					"Content-Type": "application/json",
					Authorization: signedToken
				}
			}
		)
		.then((res) => {
			// console.log("Notification token set on server");
		})
		.catch((err) => {
			// console.log("Error setting notification token on server");
		});
};

/**
 * Start a video call
 * @param {string} buildingId
 * @param {string} deviceId
 * @param {string} signedToken
 * @returns {Promise} A promise that resolves to the call url
 */
export const startVideoCall = (buildingId, deviceId, signedToken) => {
	return axios.post(
		requestVideoCall,
		{
			buildingID: buildingId,
			deviceID: deviceId,
			origin: 4
		},
		{
			headers: {
				"Content-Type": "application/json",
				Authorization: signedToken
			}
		}
	);
};

export const sendMessage = (buildingID , deviceId , signedToken , msg , type) => {
	let url = actionsUrl+'/'+buildingID
	let date = new Date();
	let hours = date.getHours() < 10 ?  `0${date.getHours()}` :  date.getHours();
	let minutes = date.getMinutes() < 10 ?  `0${date.getMinutes()}` :  date.getMinutes();
	return axios.post(
		url,
		{
			action:{
				day: {
					date: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
				},
				devices: [deviceId],
				repeat: false,
				text: msg,
				time: `${hours}:${minutes}`,
				type: "note",
				confirmed: false,
				remove:true
			}
		},{
			headers: {
				"Content-Type": "application/json",
				Authorization: signedToken
			}
		}
	)
}

export const massiveMsgs = (buildings, signedToken, msg) => {

	return axios.post(
		massiveMsgsURL,
		{
			buildings,
			msg
		},{
			headers: {
				"Content-Type": "application/json",
				Authorization: signedToken
			}
		}
	)

}