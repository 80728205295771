import React from "react";
import "./index.css";
import App from "./App";

const CustomCalendarView = () =>{



  return (
      <App />
  )
}

export default CustomCalendarView;
