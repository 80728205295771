import { createContext, useState } from "react";
import { fcmUrl } from "../urls";
import axios from "axios";

// Create a context
export const UserContext = createContext({
	// This is the default value
	// It will be overwritten by the provider
	user: null,
	saveUserState: () => null,
	logOutUser: () => null,
	setNotificationToken: () => null
});

/**
 * Remove a notification token from the database
 * @param {string} signedToken
 * @param {string} notificationToken
 */
const deleteNotificationToken = async (signedToken, notificationToken) => {
	await axios.delete(
		fcmUrl + "/delete",

		{
			data: {
				notificationToken: notificationToken
			},
			headers: {
				"Content-Type": "application/json",
				Authorization: signedToken
			}
		}
	);
};

// Create a provider
export const UserProvider = ({ children }) => {
	const [user, setUser] = useState(localStorage.getItem("user")? (JSON.parse(localStorage.getItem("user"))) : null);
	const [notificationToken, setNotificationToken] = useState(null);

	const logInUser = (email, signedToken) => {
		const u = { email, signedToken };
		localStorage.setItem("user", JSON.stringify(u));
		setUser(u);
	};

	const logOutUser = () => {
		deleteNotificationToken(
			user.signedToken,
			notificationToken,
			user.email
		);
		localStorage.removeItem("user");
		setUser(null);
		setNotificationToken(null);
		// ! This is not working properly, so do not use it
		// deleteToken(messaging).catch((err) => {
		// 	console.log("Error deleting token");
		// });
	};

	return (
		<UserContext.Provider
			value={{
				saveUserState: logInUser,
				logOutUser,
				user,
				setNotificationToken
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
